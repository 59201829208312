import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Empty, Form, Input, Select } from 'antd';
import { Modal } from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import { STATUS } from '../../Constants';
import { filter, find, map } from 'lodash';
import { copyCourseSubjectChapterAction, resetCopyCourseSubjectStatus } from '../../redux/reducers/courses';


const { Option } = Select;


const ContentTypes = [
    { label :'ebooks', value : 'ebooks' }, 
    { label : 'texts', value : 'texts' }, 
    { label : 'audios', value : 'audios'},
    {label : 'videos', value : 'videos' }, 
    { label :'documents', value: 'documents'},
    { label :'tests', value : 'tests'}
]


export default function CopyCourseChapterModal({ visible, copyToCourseId, copyToCourseContentId, copyToChapterId, closeModal }) {
    
    const [ selectedCourse, setSelectedCourse ] = useState(null);
    const [ selectedSubject, setSelectedSubject ] = useState();
    const [ selectedChapter, setSelectedChapter ] = useState();
    const [ filterContenetTypes, setFilterContenetTypes ] = useState([]);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    
    const { course } = useSelector((state) => ({
        course: state.course,
    }));

    const onFinish = useCallback(() => {
        const selectedCourseData = find(course?.courseList, c => c._id === selectedCourse);
        const subjectContent = find(selectedCourseData.subjects, s => s._id === selectedSubject)
        dispatch(copyCourseSubjectChapterAction({
            oldCourseContentId: subjectContent.content,
            newCourseContentId: copyToCourseContentId,
            oldChapterId: selectedChapter,
            newChapterId: copyToChapterId,
            filterContenetTypes
        }));
    }, [course?.courseList, filterContenetTypes, copyToChapterId, dispatch, copyToCourseContentId, selectedChapter, selectedCourse, selectedSubject])

    const onCourseChange = useCallback((id) => {
        setSelectedCourse(id);
        setSelectedSubject();
        setSelectedChapter();
        form.setFieldsValue({ subject: null });
        form.setFieldsValue({ chapter: null });
    }, [setSelectedChapter, form, setSelectedSubject, setSelectedCourse])

    const handleSubjectChange = useCallback((id) => {
        setSelectedSubject(id);
        setSelectedChapter();

        form.setFieldsValue({ chapter: null });
    }, [setSelectedChapter, form, setSelectedSubject])

    const filterCourses = useMemo( () => { 
        return filter(course?.courseList, c => c._id !== copyToCourseId );
     },[copyToCourseId, course])

    const filterSubjects = useMemo( () => { 
        return find(course?.courseList, s => s._id === selectedCourse )?.subjects;
     },[course, selectedCourse])

    const filterChapters = useMemo( () => { 
        const subjects = find(course?.courseList, s => s._id === selectedCourse )?.subjects;
        return subjects ? find(subjects, s => s?._id === selectedSubject )?.template?.chapters : null;
     },[course?.courseList, selectedCourse, selectedSubject])

     const handleCloseModal = useCallback(() => {
        form.resetFields();
        closeModal();
     },[closeModal, form])

     useEffect(() => {
        if(course.copyCourseSubjectChapterStatus === STATUS.SUCCESS){
            dispatch(resetCopyCourseSubjectStatus())
            handleCloseModal();
        }
     }, [course.copyCourseSubjectChapterStatus, handleCloseModal, dispatch])

    return (
        <div>
            <div>
                <Modal title='Copy Course Chapters From' visible={visible}
                    okText='Copy Chapter'
                    onOk={onFinish} onCancel={handleCloseModal}
                    okButtonProps={{ disabled: !selectedCourse || !selectedSubject || !selectedChapter }}
                    confirmLoading={ course.copyCourseSubjectChapterStatus === STATUS.FETCHING }
                >
                    {course.getCoursesStatus === STATUS.SUCCESS &&
                        filterCourses?.length ? (
                        <Form name="control-ref" form={form}>
                            <Form.Item name="course" label="Course" rules={[{ required: true }]}>
                                <Select
                                    placeholder="Select a option"
                                    onChange={onCourseChange}
                                    allowClear
                                >
                                    {
                                        map(filterCourses, course => 
                                            <Option key={course._id} value={course._id}>{course.name}</Option> )
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item name="subject" label="Subject" rules={[{ required: true }]}>
                                <Select
                                    placeholder="Select a option "
                                    onChange={ handleSubjectChange }
                                    allowClear
                                >
                                    {
                                        map(filterSubjects, s =>
                                            <Option key={s._id} value={s._id}>{s.displayName}</Option> )
                                    }
                                </Select>
                            </Form.Item>
                            
                            <Form.Item name="chapter" label="Chapter" rules={[{ required: true }]}>
                                <Select
                                    placeholder="Select a option"
                                    onChange={setSelectedChapter}
                                    allowClear
                                >
                                    {
                                        map(filterChapters, c =>
                                            <Option key={c._id} value={c.chapterId._id}>{c.chapterId.name?.en || c.chapterId.name?.hn}</Option> )
                                    }
                                </Select>
                            </Form.Item>

                             <Form.Item name="filterContenetTypes" label="Content Types Copy" rules={[{ required: true }]}>
                                <Select
                                    mode="multiple"
                                    size={'md'}
                                    placeholder="select"
                                    value={filterContenetTypes}
                                    onChange={setFilterContenetTypes}
                                    style={{ width: '100%' }}
                                    options={ContentTypes}
                                    />
                            </Form.Item>
                        </Form>
                    ) : (
                        <Empty description="No course added" />
                    )}
                </Modal>
            </div>
        </div>
    )
}
