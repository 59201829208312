import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { map } from "lodash"
import { STATUS } from "../../../Constants"
import { ErrorMessage, SuccessMessage } from "../../../Constants/CommonAlerts"
import { URIS } from "../../../services/api"
import { apis } from "../../../services/api/apis"

export const getAllCareerRequest = createAsyncThunk(
    'get/career-request/all',
    async (payload, thunkAPI) => {
        const response = await apis.getAllCareerRequest(payload)
        const { ok, problem, data } = response
        if (ok) {
            return data
        } else {
            return thunkAPI.rejectWithValue(problem)
        }
    }
)

export const updateCareerRequest = createAsyncThunk(
    'patch/career-request',
    async (payload, thunkAPI) => {
        const response = await apis.updateCareerRequest(payload)
        const { ok, problem, data } = response
        if (ok) {
            return data
        } else {
            return thunkAPI.rejectWithValue(problem)
        }
    }
)

const initialState = {
    getAllCareerRequestStatus: STATUS.NOT_STARTED,
}

const userSlice = createSlice({
    name: "career",
    initialState,
    reducers: {
        setActiveStaff: (state, action) => {
            state.activeStaff = action.payload
            state.user = action.payload?.user
        },
    },
    extraReducers: {
        [getAllCareerRequest.pending]: (state, action) => {
            state.getAllCareerRequestStatus = STATUS.FETCHING
        },
        [getAllCareerRequest.fulfilled]: (state, action) => {
            state.getAllCareerRequestStatus = STATUS.SUCCESS
            state.careerRequests = action.payload
        },
        [getAllCareerRequest.rejected]: (state, action) => {
            state.getAllCareerRequestStatus = STATUS.FAILED
        },
        [updateCareerRequest.pending]: (state, action) => {
            state.updateCareerRequestStatus = STATUS.FETCHING
        },
        [updateCareerRequest.fulfilled]: (state, action) => {
            SuccessMessage()
            state.updateCareerRequestStatus = STATUS.SUCCESS
            state.careerRequests = ({ ...state.careerRequests, docs: map(state?.careerRequests?.docs, doc => doc._id === action.payload._id ? action.payload : doc) })
        },
        [updateCareerRequest.rejected]: (state, action) => {
            state.updateCareerRequestStatus = STATUS.FAILED
            ErrorMessage()
        },
    },
})

export const careerReducer = userSlice.reducer