import { DownOutlined, RedoOutlined } from "@ant-design/icons";
import { Text } from "@chakra-ui/react";
import { Button, Dropdown, Image, Input, Menu, Modal, Select, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CommonPageHeader } from "../../components/CommonPageHeader";
import { STATUS } from "../../Constants";
import { ConfirmAlert } from "../../Constants/CommonAlerts";
import { getAllCareerRequest, updateCareerRequest } from "../../redux/reducers/career";

export default function CareerRequestList() {

    const limit = 10
    const dispatch = useDispatch()

    const [inputFilter, setInputFilter] = useState()

    useEffect(() => {
        dispatch(getAllCareerRequest({ limit: limit, page: 1 }))
    }, [dispatch])

    const { careerRequests, getAllCareerRequestStatus } = useSelector(s => ({ careerRequests: s.career.careerRequests, getAllCareerRequestStatus: s.getAllCareerRequestStatus }))

    const openCV = (url) => {
        window.open(url, '_blank')
    }

    const updateStatus = (id, oldStatus, status) => {
        ConfirmAlert(() => dispatch(updateCareerRequest({ id: id, status: status })), 'Confirmation Alert', `Are you sure? Do You want to update status from ${oldStatus} to ${status} ?`, getAllCareerRequestStatus === STATUS.FETCHING)
    }

    const columns = [
        { title: <b>First Name</b>, dataIndex: 'name', key: 1 },
        { title: <b>Last Name</b>, dataIndex: 'lastName', key: 2 },
        { title: <b>Email</b>, dataIndex: 'email', key: 3 },
        { title: <b>Position</b>, dataIndex: 'applyingPosition', key: 4 },
        {
            title: <b>Status</b>, width: 140, key: 5,
            render: d =>
                <div>
                    <Dropdown
                        overlay={
                            <Menu style={{ width: 100 }}>
                                <Menu.Item onClick={() => updateStatus(d._id, d.status, "ACTIVE")}>ACTIVE</Menu.Item>
                                <Menu.Item onClick={() => updateStatus(d._id, d.status, "CLOSE")}>CLOSE</Menu.Item>
                                <Menu.Item onClick={() => updateStatus(d._id, d.status, "HIRED")}>HIRED</Menu.Item>
                                <Menu.Item onClick={() => updateStatus(d._id, d.status, "REJECTED")}>REJECTED</Menu.Item>
                            </Menu>
                        }
                        trigger={['click']}>
                        <Space style={{ cursor: "pointer" }}>
                            {d.status}
                            <DownOutlined />
                        </Space>
                    </Dropdown>
                </div>
        },
        {
            title: <b>CV</b>, width: 150, dataIndex: 'cvUrl', key: 6,
            render: d => {
                console.log(d);
                return <Text style={{ color: "blue" }} cursor="pointer" onClick={() => openCV(d)}>View CV</Text>
            }
        },

    ]

    const handleCurrentPage = (d) => {
        dispatch(getAllCareerRequest({ limit: limit, page: d.current }))
    }

    const inputHandler = (val) => {
        setInputFilter(pre => ({ ...pre, ...val }))
    }

    const filterData = () => {
        dispatch(getAllCareerRequest({ name: inputFilter?.name, lastName: inputFilter?.lastName, status: inputFilter?.status, limit: limit, page: 1 }))
    }

    const resetFilter = () => {
        setInputFilter()
    }

    return (
        <div>
            <CommonPageHeader title='Career Request' />
            <br />
            <Space>
                <Input type="text" id="firstName" placeholder="Search By First Name" value={inputFilter?.name} onChange={(e) => inputHandler({ name: e.target.value })} />
                <Input type="text" id="lastName" placeholder="Search By Last Name" value={inputFilter?.lastName} onChange={(e) => inputHandler({ lastName: e.target.value })} />
                <Select style={{ width: 150, }} value={inputFilter?.status} placeholder="Select Status" onChange={(e) => inputHandler({ status: e })}
                    options={[
                        { value: 'ACTIVE', label: 'ACTIVE' },
                        { value: 'CLOSE', label: 'CLOSE' },
                        { value: 'HIRED', label: 'HIRED' },
                        { value: 'REJECTED', label: 'REJECTED' },
                    ]}
                />
                <Button type="primary" onClick={filterData}>Filter</Button>
                <Button onClick={resetFilter}><RedoOutlined />Reset</Button>
            </Space>
            <br />
            <Table loading={getAllCareerRequestStatus === STATUS.FETCHING} bordered columns={columns} dataSource={careerRequests?.docs}
                pagination={{
                    total: careerRequests?.total,
                    pageSize: limit,
                    showSizeChanger: false,
                    current: careerRequests?.page,
                }}
                onChange={handleCurrentPage}>
            </Table>
        </div>

    )
}

