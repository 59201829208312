import { Button, Card, DatePicker, Descriptions, Drawer, Form, Input, Radio, Space } from 'antd'
import React, { useState } from 'react'
import _ from 'lodash'
import Text from 'antd/lib/typography/Text'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import { apis } from '../../services/api/apis'
import { ErrorMessage, FetchingMessage, SuccessMessage } from '../../Constants/CommonAlerts'

export const UpdateStudentPackageModal = (props) => {
    return (
        <Drawer visible={props.visible} title='Edit' width={'50%'} onClose={props.closeModal} >
            {
                props.studentPackageRoll ?
                    <DataModel {...props} /> : null
            }
        </Drawer>
    )
}

const DataModel = ({ visible, closeModal, onSuccess, studentPackageRoll, currentPkg }) => {
    console.log('modal', { studentPackageRoll, visible, currentPkg })

    // const stpkg = studentPackageRoll?.packages?.[0]
    let stpkg = studentPackageRoll?.packages?.length && _.findIndex(studentPackageRoll?.packages, p => p.package === currentPkg._id) !== -1 && _.find(studentPackageRoll.packages, p => p.package === currentPkg._id)
    const [mode, setMode] = useState(stpkg?.mode || "online")
    const [center, setCenter] = useState(stpkg?.center)
    
    const _save = async () => {
        const params = {}
        params.mode = mode
        if (mode === "offline") {
            params.center = center
        } else {
            params.center = null
        }
        params.userId = studentPackageRoll?.user?._id
        params.packageId = stpkg?.package

        FetchingMessage()
        const res = await apis.updateStudentPackageDetails(params)
        if (res.ok) {
            SuccessMessage()
            onSuccess()
        } else {
            ErrorMessage()
        }
    }
    console.log({ mode, m: stpkg?.mode })


    return (
        <Card style={{ border: 0 }} bodyStyle={{ padding: 0 }} >
            <div style={{ padding: "10px" }}>
                <Descriptions bordered column={1} style={{ marginBottom: "20px" }}>
                    <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label="Package">{currentPkg?.name?.en}</Descriptions.Item>
                    <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label="Roll No.">{studentPackageRoll?.rollNumber?.finalRoll}</Descriptions.Item>

                </Descriptions>

                <Form.Item label="Mode" name="mode">
                    <Checkbox
                        checked={mode === "offline"}
                        onChange={() => setMode("offline")}
                    >
                        Offline
                    </Checkbox>
                    <Checkbox
                        checked={mode === "online"}
                        onChange={() => setMode("online")}

                    >
                        Online
                    </Checkbox>
                </Form.Item>


                {
                    mode === "offline" ?
                        <Form.Item label="Center">
                            {
                                _.map(currentPkg?.centers, c => {
                                    return (
                                        <div key={c.code}
                                        >
                                            <Checkbox
                                                checked={c.code === center?.code}
                                                onChange={() => setCenter(c)}
                                            >
                                                <div>
                                                    {c.name}
                                                </div>
                                                <div>
                                                    {
                                                        c.code
                                                    }
                                                </div>
                                            </Checkbox>
                                        </div>
                                    )
                                })
                            }

                        </Form.Item>
                        : null}

                <Button onClick={_save}>Save</Button>

                {/* <Form.Item label='Assign On'>
                <DatePicker  value={currentPkg.assignedOn ? moment(currentPkg.assignedOn) : null}/>
            </Form.Item> */}
            </div>
        </Card>
    )
}