import { Box, Text } from "@chakra-ui/react";
import { Button, DatePicker, Popconfirm, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import { SelectTestForPackageModal } from "./SelectTestForPackageModal";
import { useDispatch, useSelector } from "react-redux";
import { compact, findIndex, flatMap, map, size } from "lodash";
import moment from "moment";
import { addPkgDemoAction, deleteContentAction } from "../../redux/reducers/packages";
// SelectTestForPackageModal
export const ManageTest = ({ content, currentPackage, }) => {
    const dispatch = useDispatch()
    const [selectTestModal, selectTestModalChange] = useState(false)

    const { addPkgDemoStatus } = useSelector(state => ({
        addPkgDemoStatus: state.packages.addPkgDemoStatus,
    }))

    // const changeDateRange = (e, id) => {
    //     let startDate = e[0]
    //     let endDate = e[1]

    //     assignData({ id: id, startDate: startDate, endDate: endDate })
    // }

    const _assignData = (data, type) => {
        assignData({ value: data[0]?.test, type })
        // assignData({ value: compact(map(data, "tests")), type })
    }

    const assignData = (d) => {
        const data = { ...d, packageId: currentPackage._id }
        dispatch(addPkgDemoAction(data))
    }
    useEffect(() => {
        selectTestModalChange(false)
    }, [addPkgDemoStatus])

    const handleDelete = (id) => {
        dispatch(deleteContentAction({type:'test', contentId:currentPackage.demoContent._id, id}))
    }
    return (
        <Box>
            <Button type='primary' ghost onClick={() => selectTestModalChange(true)}>Select Test</Button>
            <br /><br />
            <Table
                bordered
                dataSource={content}
                pagination={false}
            >
                <Table.Column title='Ref Id' key='referenceId' render={d => <Text>{d?.data?.id}</Text>} />
                <Table.Column title='Name' key='name' render={d => <Text>{d?.data?.name?.en}</Text>} />
                <Table.Column title='Platform' key='testType' render={d => <Text>{d?.data?.testType}</Text>} />
                <Table.Column title='Time Duration (min)' key='time' render={d => <Text>{d?.data?.totalTime}</Text>} />
                <Table.Column title='Questions' key='queCont' render={d => size(flatMap(d?.data?.sections, 'questions'))} />
                <Table.Column title='Status' key='status' render={d => <Text>{d?.data?.status}</Text>} />
                <Table.Column title='Actions' render={d => 
                    {
                        return(
                            <Space>
                                <Popconfirm placement="topLeft" title={'Are you sure?'} 
                                    onConfirm={() => handleDelete(d._id)} okText="Yes" cancelText="No"
                                >
                                    <Button size='small' danger>Delete</Button>
                                </Popconfirm>
                            </Space>
                        )
                    }
                }>
                </Table.Column>
                {/* <Table.Column
                    title='Date'
                    key='date'
                    render={data => {
                        let startDate = data?.testPackageDetails?.startDate ? moment(data?.testPackageDetails?.startDate) : null
                        let endDate = data?.testPackageDetails?.endDate ? moment(data?.testPackageDetails?.endDate) : null
                        return (
                            <DatePicker.RangePicker showTime allowClear={false} onChange={(e) => changeDateRange(e, data?.testPackageDetails?._id)} defaultValue={[startDate, endDate]} />
                        )
                    }}
                /> */}
                {/* <Table.Column title='Action' key='action' align='center'
                    render={data => <Button danger onClick={() => removeTest(data.testPackageDetails._id, 'tests')}>
                        <DeleteOutlined style={{ fontSize: '14px' }} />
                    </Button>
                    }
                /> */}
            </Table>
            {selectTestModal ?
                <SelectTestForPackageModal
                    isDemoContent={true}
                    preSelectedTests={map(content, c => ({ _id: c.data?._id }))}
                    visibility={selectTestModal}
                    closeModal={() => selectTestModalChange()}
                    submitTestData={(data) => _assignData(data, 'test')}
                />
                : null
            }
        </Box>
    )
}