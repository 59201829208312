import { Button, Card, DatePicker, Divider, Empty, Table, Tooltip } from 'antd'
import React, { useCallback, useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthUser } from '../../App/Context'
import { STATUS } from '../../Constants'
import { getAssignmentsAction, resetGetAssignments } from '../../redux/reducers/assignments'
import { getCoursesAction } from '../../redux/reducers/courses'
import { getAllProductsAction } from '../../redux/reducers/products'
import _ from 'lodash'
import Text from 'antd/lib/typography/Text'
import { schedulePackageTestAction, updatePackageAction, updatePackageContentAction } from '../../redux/reducers/packages'
import { ConfirmAlert } from '../../Constants/CommonAlerts'
import { DeleteOutlined, UserSwitchOutlined } from '@ant-design/icons'
import moment from 'moment'
import { SelectTestForPackageModal } from '../ManagePackages/SelectTestForPackageModal'
import { SelectPackageModal } from './SelectPackageModal'
import { bilingualText } from '../../utils/FileHelper'
import { Link, useHistory } from 'react-router-dom'

export const AssignPackageTests = ({ currentPackage, contentTypes }) => {
    const dispatch = useDispatch()

    const { course, product, packageReducer, assignments } = useSelector((state) => ({
        course: state.course,
        product: state.product,
        packageReducer: state.packages,
        assignments: state.assignments
    }))

    useEffect(() => {
        return () => {
            dispatch(resetGetAssignments())
        }
    }, [])

    const assignData = (d) => {
        const data = { ...d, packageId: currentPackage._id }
        dispatch(updatePackageAction(data))
    }

    const scheduleTest = (d) => {
        const data = { ...d, packageId: currentPackage._id }
        dispatch(schedulePackageTestAction(data))
    }

    const loading = packageReducer.updatePkgContentStatus === STATUS.FETCHING

    return (
        <Card loading={assignments.getAssignmentsStatus === STATUS.FETCHING}
            style={{ border: 0, minHeight: '500px' }}
            bodyStyle={{ padding: 0 }}
        >
            <Text style={{ fontWeight: 'bold', fontSize: '18px' }}>Assign Content</Text>
            <br /><br />
            <TestCard product={product} loading={loading} assignData={assignData} scheduleTest={scheduleTest} currentPackage={currentPackage} />
        </Card>
    )
}

const TestCard = ({ assignData, currentPackage, loading, scheduleTest }) => {
    const [selectTestModal, selectTestModalChange] = useState(false)
    const history = useHistory()

    const _assignData = (data, type) => {
        let newData = _.map(data, d => {
            let findTest = _.findIndex(currentPackage?.tests, s => s.test._id === d.test)
            if (findTest !== -1) {
                let test = currentPackage.tests[findTest]
                d = Object.assign({}, d, { startDate: test?.startDate, endDate: test?.endDate })
            }
            return d
        })
        assignData({ value: newData, path: type })
    }

    const removeTest = (data, type) => {
        ConfirmAlert(() => assignData({ value: data, path: type, remove: true }), 'Are you Sure?')
    }

    useEffect(() => {
        selectTestModalChange(false)
    }, [loading])

    const changeDateRange = (e, id) => {
        let startDate = e[0]
        let endDate = e[1]

        scheduleTest({ id: id, startDate: startDate, endDate: endDate })
    }

    const assignedTests = _.map(currentPackage?.tests, s => {
        let { test, ...otherData } = s
        return (
            Object.assign({}, test, { testPackageDetails: otherData })
        )
    })

    const [packageModal, openPackageModal] = useState()

    const _openPkgModal = () => {
        openPackageModal(d => !d)
    }

    const _setPackages = (pkgs) => {
        const data = { packages: pkgs?.length ? pkgs.map(p => p._id) : [] }
        assignData(data)
    }

    const _viewStudents = (data) => {
        history.push({pathname:'/package-students/' + data._id})
    }

    return (
        <Card bodyStyle={{ padding: '10px' }} style={{ border: '1px solid #AEB6BF' }} loading={loading}>
            {packageModal &&
                <SelectPackageModal
                    defaultPackages={currentPackage.packages?.length ? currentPackage.packages.map(pkg => pkg._id) : null}
                    onSubmit={_setPackages} visible={packageModal} closeModal={_openPkgModal}

                />
            }
            <div style={{ background: '#E8F6F3', padding: '5px 20px' }}>
                <Text style={{ fontWeight: 'bold', color: '#3498DB', fontSize: '18px' }}>Packages</Text>
            </div>
            <Divider style={{ margin: '10px' }} />
            <Button type='primary' ghost onClick={_openPkgModal}>Select Package</Button>
            <br /><br />
            <Table
                bordered
                dataSource={currentPackage.packages || []}
                pagination={false}
            >
                <Table.Column title='Name' key='name' render={d => <Link onClick={() => history.push('/update-package/1/' +d._id)}>{bilingualText(d.name)}</Link>} />
                <Table.Column title='Action' key='action' align='center'
                    render={data =>
                        <Tooltip title='Students'>
                            <Button onClick={() => _viewStudents(data)} icon={<UserSwitchOutlined />} />
                        </Tooltip>
                    }
                />
            </Table>
        </Card>
    )
}