import XLSX from 'xlsx'
import _ from 'lodash';
import { Tag } from 'antd';
import Text from 'antd/lib/typography/Text';
import { useLocation } from 'react-router-dom';

export const isPDF = (mime) => {
  return mime === "application/pdf";
};

export const createBlob = async (base64Data) => {
  let blobfile = await fetch(`${base64Data}`);
  blobfile = await blobfile.blob();
//   console.log("blob", blobfile);
  return blobfile;
};

export const sheetToJSON = (files, onSave) => {

  var selectedFile = files[0]
  let jsonObject
  if(selectedFile){
    let finaldata =[]

      var fileReader = new FileReader()
      fileReader.onload = (e) => {
          var data = e.target.result
          var workbook = XLSX.read(data, {type:'binary'})
        
          workbook.SheetNames.forEach(sheet => {
              let rowObject = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheet], {raw: false, defval: ''})
              onSave(rowObject)
          })
      }
      fileReader.readAsBinaryString(selectedFile)

    }
}

export const checkHtml = strng => {    
  var a = document.createElement('div');
  a.innerHTML = strng;

  for (var c = a.childNodes, i = c.length; i--; ) {
    if (c[i].nodeType == 1) return true; 
  }

  return false
}

export const bilingualText = (obj) =>
  !obj
    ? ""
    : `${obj.en || ""}${obj.en && obj.hn ? " / " : ""}${obj.hn || ""}`;

    
export const setRecentlyUsedTags = (tags) => {
  if(tags.length > 0){
    let currentData = localStorage.getItem('recentTags') ? JSON.parse(localStorage.getItem('recentTags')) : []
    let currentTags = [...tags]
    let newData = []

    // if(currentData.length < 8){
    //   newData = _.uniqBy(_.concat(currentData, _.slice(currentData, 0, (8 - currentData.length))), '_id')
    // }else{
    //   newData = _.slice(currentTags, 0, 8)
    // }

    newData = _.slice(currentTags, 0, 8)

    localStorage.setItem('recentTags', JSON.stringify(newData))
  }
}

export const getRecentlyUsedTags = () => {
  return localStorage.getItem('recentTags') ? JSON.parse(localStorage.getItem('recentTags')) : []
}

export const checkOptionsData = (options, type) => {
  let added = false
  
  let data = options.forEach((item, index, arr) => {
    if(item.en != '' || item.hn != ''){
      added = true
    }  
  })
  return added
}

export const expiryCheck = (start, end) => {
  let currentDate = new Date()
  let startDate = new Date(start)
  let endDate = new Date(end)
  let started = (startDate - currentDate) <= 0
  let expired = (endDate - currentDate) <= 0
  let daysLeftToExpire = started && !expired ? Math.round(Math.abs(endDate.getTime() - currentDate.getTime())/(1000*3600*24)) : 0
  let daysLeftToStart = !started ? Math.round(Math.abs(startDate.getTime() - currentDate.getTime())/(1000*3600*24)) : 0
  
  return daysLeftToStart ? 
      <span color='orange'>{'start in ' + daysLeftToStart + ' days'}</span> 
        : daysLeftToExpire ? <span color='green'>{'expire in '+daysLeftToExpire + ' days' }</span>
          : <span color='red'>expired</span>
}

export const renderMathTex = (id) => {
  window.renderMathInElement(document.getElementById(id || "math-tex-id", {
      delimiters: [
          { left: "$", right: "$", display: true }
      ]
  })); 
}

export function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}