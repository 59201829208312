import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Card, DatePicker, Drawer, Form, Input, Modal, Radio, Space, Table, Tag, Tooltip } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CommonPageHeader } from '../../components/CommonPageHeader'
import _ from 'lodash'
import { addEventAction, deleteEventAction, getAllEventAction, resetAddEvent, updateEventAction } from '../../redux/reducers/events'
import { STATUS } from '../../Constants'
import confirm from 'antd/lib/modal/confirm'
import { ConfirmAlert } from '../../Constants/CommonAlerts'
import TextArea from 'antd/lib/input/TextArea'

export const Events = () => {
    const dispatch = useDispatch()
    const [eventDrawer, openEventDrawer] = useState()
    const [editEventDrawer, openEditEventDrawer] = useState()
    const [descriptions, openDescription] = useState([])

    const {getAllEventStatus, eventList, deleteEventStatus} = useSelector(state => ({
        getAllEventStatus:state.event.getAllEventStatus,
        eventList:state.event.eventList,
        deleteEventStatus:state.event.deleteEventStatus
    }))
    
    useEffect(() => {
        dispatch(getAllEventAction())
    }, [])

    const _openEventDrawer = () => {
        openEventDrawer(!eventDrawer)
    }

    const editEvent = (d) => {
        openEditEventDrawer(d?._id ? d : false)
    }

    const deleteEvent = (d) => {
        ConfirmAlert(() => dispatch(deleteEventAction({eventId:d._id})), 'Sure?', null, deleteEventStatus === STATUS.FETCHING)
    }

    const _openDescirpton = (obj) => {
        openDescription(d => _.xor(d, [obj._id]))
    }

    const filters = [
        {
            text: "Active",
            value: true,
          },
          {
            text: "InActive",
            value: false,
          },
    ]
    const onFilter = (value, record) => {
        record = Object.assign({}, record, {
          active: record.active || false,
        });
        return record.active === value;
      }

    return(
        <div>
            <CommonPageHeader title='Manage Events'
                extra={<Button size='large' onClick={_openEventDrawer} icon={<PlusOutlined/>}>Add Event</Button>}
            />
            <br/>
            <Card>
                <Table loading={getAllEventStatus === STATUS.FETCHING} bordered dataSource={eventList}>
                    <Table.Column title='Title' dataIndex='title'></Table.Column>
                    <Table.Column width='40%' title='Description' dataIndex='body'
                        render={(d, obj) => {
                            let readMore = _.findIndex(descriptions,des => des === obj._id) != -1
                            return d?.length > 240 ? 
                             <div>
                                {readMore ? d : d.substring(0, 240)+'...'}
                                {readMore ? 
                                    <Button type='link' color='blue' style={{padding:0}} size='xs' onClick={() => _openDescirpton(obj)}>read less</Button>
                                    :
                                    <Button type='link' color='blue' style={{padding:0}} size='xs' onClick={() => _openDescirpton(obj)}>read more</Button>
                                }
                            </div> : d
                        }}
                    ></Table.Column>
                    <Table.Column title='Start & End Date' key='date'
                        render={d => d.startDate ? moment(d.startDate).format('DD-MM-YYYY') + ' to ' + moment(d.endDate).format('DD-MM-YYYY') : '-'}
                    ></Table.Column>
                    <Table.Column title='Start & End Time' key='time'
                        render={(d, obj) => obj.startTime ? moment(obj.startTime).format('HH:mm A') +  (obj.endTime ? ' - ' + moment(obj.endTime).format('HH:mm A') : '') : '-'}
                    ></Table.Column>
                    <Table.Column filters={filters} onFilter = {onFilter} title='Active' dataIndex='active' key='active'
                        render={d => d ? <Tag color='success'>Active</Tag> : <Tag color='red'>Inactive</Tag> }
                    ></Table.Column>
                    <Table.Column title='Priority' dataIndex='priority'></Table.Column>
                    <Table.Column title='Actions' key='actions'
                        render={d => 
                            <Space>
                                <Tooltip title='Edit'>
                                    <Button icon={<EditOutlined />} onClick={() => editEvent(d)}></Button>
                                </Tooltip>
                                <Tooltip title='Delete'>
                                    <Button icon={<DeleteOutlined />} onClick={() => deleteEvent(d)}></Button>
                                </Tooltip>
                            </Space>
                        }
                    ></Table.Column>
                </Table>
            </Card>
            {eventDrawer ? <AddEvent visible={eventDrawer} closeModal={_openEventDrawer}  /> : null}
            {editEventDrawer ? <AddEvent visible={editEventDrawer} closeModal={editEvent} currentEvent={editEventDrawer} /> : null}
        </div>
    )
}

const AddEvent = ({visible, closeModal, currentEvent}) => {
    const dispatch = useDispatch()

    const {addEventStatus, updateEventStatus} = useSelector(state => ({
        addEventStatus:state.event.addEventStatus,
        updateEventStatus:state.event.updateEventStatus
    }))

    useEffect(() => {
        return () => dispatch(resetAddEvent())
    }, [])

    useEffect(() => {
        if(addEventStatus === STATUS.SUCCESS || updateEventStatus === STATUS.SUCCESS)
            closeModal()
    }, [addEventStatus, updateEventStatus])
    
    const addEvent = (formData) => {
        let data = {..._.omit(formData, ['date']), startDate:formData.date?.[0]?.format('YYYY-MM-DD') || '', endDate:formData.date?.[1]?.format('YYYY-MM-DD') || '', priority:parseInt(formData.priority),
            startTime:formData.startTime?.toDate() || '', endTime:formData.endTime?.toDate() || ''
        }

        if(currentEvent)
            dispatch(updateEventAction({...data, eventId:currentEvent._id}))
        else
            dispatch(addEventAction(data))
    }

    return(
        <Drawer width='60%' visible={visible} onClose={closeModal} title={currentEvent ? 'Update Event' : 'Add Event'}>
            <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
                onFinish={addEvent}
            >
                <Form.Item label='Title' initialValue={currentEvent?.title} rules={[{required:true, message:'Please fill in the field.'}]} name='title'>
                    <Input placeholder='Name'/>
                </Form.Item>
                <Form.Item label='Description' initialValue={currentEvent?.body} name='body'>
                    <TextArea rows={4} placeholder='Description'/>
                </Form.Item>
                <Form.Item label='Event Start & End Date' initialValue={currentEvent?.startDate ? [moment(currentEvent?.startDate) , moment(currentEvent?.endDate)] : []} name='date'>
                    <DatePicker.RangePicker />
                </Form.Item>
                <Form.Item label='Event Start Time' initialValue={currentEvent?.startTime ? moment(currentEvent?.startTime) : null} name='startTime'>
                    <DatePicker picker='time' placeholder='Start Time' format='HH:mm' />
                </Form.Item>
                <Form.Item label='Event End Time' initialValue={currentEvent?.endTime ? moment(currentEvent?.endTime) : null} name='endTime'>
                    <DatePicker picker='time' placeholder='End Time' format='HH:mm'/>
                </Form.Item>
                <Form.Item label='Active' name='active' initialValue={currentEvent? currentEvent.active : true}>
                    <Radio.Group>
                        <Radio.Button value={true}>
                            Yes
                        </Radio.Button>
                        <Radio.Button value={false}>
                            No
                        </Radio.Button>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label='Priority' name='priority' initialValue={currentEvent?.priority}>
                    <Input type='number' placeholder='Priority' min={0}/>
                </Form.Item>
                <Form.Item label='Button Name' name='buttonName' initialValue={currentEvent?.buttonName}>
                    <Input type='text' placeholder='Button Name'/>
                </Form.Item>
                <Form.Item label='Button Link' name='link' initialValue={currentEvent?.link}>
                    <Input type='url' placeholder='Button Link'/>
                </Form.Item>
                <Form.Item wrapperCol={{span:16, offset:6}}>
                    <Button loading={addEventStatus === STATUS.FETCHING || updateEventStatus === STATUS.FETCHING} htmlType='submit'>
                        {currentEvent ? 'Update' : 'Add'}
                    </Button>
                </Form.Item>
            </Form>
        </Drawer>
    )
}