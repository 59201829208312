import { Button, DatePicker, Form, Input, Modal, TimePicker } from "antd";
import moment from "moment";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { STATUS } from "../../Constants";
import { updateCourseAction } from "../../redux/reducers/courses";

export const AddCourseLiveClassModal = ({ closeModal, currentCourse }) => {
    const dispatch = useDispatch()

    const { updateCourseStatus } = useSelector(s => ({
        updateCourseStatus: s.course.updateCourseStatus
    }))

    const _submit = (d) => {
        const data = { ...d, date: moment(d.date).format('YYYY-MM-DD') }
        dispatch(updateCourseAction({ liveVideo: data, id: currentCourse._id }))
    }

    const fields = useMemo(() => {
        if (currentCourse?.liveVideo) {
            return currentCourse.liveVideo
        } else return
    }, [currentCourse])

    return (
        <Modal footer={false} title='Add Live Class' visible={true} onCancel={closeModal}>

            <Form
                initialValues={{ ...fields, date: fields?.date ? moment(fields?.date) : moment(), startTime: fields?.startTime ? moment(fields.startTime) : null, endTime: fields?.endTime ? moment(fields.endTime) : null}}
                onFinish={_submit}
                wrapperCol={{ span: 14 }}
                labelCol={{ span: 6 }}
                layout="horizontal"
            >
                <Form.Item name='name' label='Name' required>
                    <Input placeholder='Name' required />
                </Form.Item>
                <Form.Item name='url' label='URL' required>
                    <Input placeholder='class url' required />
                </Form.Item>
                <Form.Item label='Source'>
                    <Input readOnly value={'Vimeo'} />
                </Form.Item>
                <Form.Item name='date' label='Date'>
                    <DatePicker />
                </Form.Item>
                <Form.Item name='startTime' label='Start Time'>
                    <TimePicker format={'hh:mm'} />
                </Form.Item>
                <Form.Item name='endTime' label='End Time'>
                    <TimePicker format={'hh:mm'} />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 6, span: 14 }}>
                    <Button loading={updateCourseStatus === STATUS.FETCHING} htmlType="submit">Add</Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}