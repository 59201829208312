import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { HStack } from '@chakra-ui/react'
import { Button, Card, Form, Input, Modal, Popconfirm, Space, Switch, Table, Tag, Tooltip, Typography } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { findIndex, flatMap, intersectionBy, map, omit, remove, size } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { STATUS } from '../../Constants'
import { updatePackageAction } from '../../redux/reducers/packages'
import { bilingualText } from '../../utils/FileHelper'
const { Text, Title } = Typography

export const PackageTestsPricing = ({ currentPackage }) => {
    const dispatch = useDispatch()

    const { updatePackageStatus } = useSelector(state => ({
        updatePackageStatus: state.packages.updatePackageStatus
    }))

    const [priceGroupModal, openPriceModal] = useState()

    useEffect(() => {
        if (updatePackageStatus === STATUS.SUCCESS)
            openPriceModal()

    }, [updatePackageStatus])

    const assignedTests = map(currentPackage?.tests, s => {
        let { test, ...otherData } = s
        return (
            Object.assign({}, test, { testPackageDetails: otherData })
        )
    })


    const _priceGroupModal = () => {
        openPriceModal(d => !d)
    }

    const _deletePlan = (obj) => {
        let data = [...currentPackage.offlinePrices]
        remove(data, d => d._id === obj._id)
        data = data?.length ? data.map(d => omit(d, ['_id'])) : data
        console.log('data', data)
        dispatch(updatePackageAction({ offlinePrices: data, packageId: currentPackage._id }))
    }

    const _editPlan = (obj) => {
        openPriceModal(d => d ? null : obj)
    }

    return (
        <div style={{ padding: 10 }}>
            <Title level={4}>Assigned Tests</Title>
            <Table
                bordered
                size='small'
                dataSource={assignedTests}
                pagination={false}
            >
                <Table.Column title='Name' dataIndex='name' key='name' render={d => <Text>{d?.en}</Text>} />
                <Table.Column title='Platform' dataIndex='testType' key='testType' />
                <Table.Column title='Time Duration (min)' dataIndex='totalTime' key='time' />
                <Table.Column title='Questions' key='queCont' render={d => size(flatMap(d.sections, 'questions'))} />
                <Table.Column title='Status' dataIndex='status' key='status' />
            </Table>
            <br /><br />
            {assignedTests?.length ?
                <>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 4, alignItems: 'center' }}>
                        <Title level={4}>Pricing Plans</Title>
                        <Button icon={<PlusOutlined />} onClick={_priceGroupModal}>Add Price Plan</Button>
                    </div>
                    <Space direction='vertical' size={10}>
                        {currentPackage.offlinePrices?.length ? currentPackage.offlinePrices.map(group =>
                            <Card key={group._id}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom:group.subjectInstruction && 2 }}>
                                    <Space size={40}>
                                        <Space>
                                            <Text style={{ fontSize: 18, fontWeight: 'bold' }}>Price: </Text>
                                            <Space>
                                                <Text style={{ fontSize: 18, fontWeight: 'bold' }} type='success' >₹{group.price}</Text>
                                                {group.fakePrice ? <Text style={{ fontSize: 14 }} type='secondary' delete>₹{group.fakePrice}</Text> : null}
                                            </Space>
                                        </Space>
                                        <Space>
                                            <Text >Show Subject Selection: </Text>
                                            {group.showSubjectSelection ? <Tag color='green'>Yes</Tag> : <Tag color='orange'>No</Tag>}
                                        </Space>
                                    </Space>
                                    <HStack>
                                        <Tooltip placement='right' title='Edit'>
                                            <Button onClick={() => _editPlan(group)} icon={<EditOutlined />} />
                                        </Tooltip>
                                        <Popconfirm
                                            title="Are you sure to delete this plan?"
                                            onConfirm={() => _deletePlan(group)}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Tooltip placement='right' title='Delete Plan'>
                                                <Button icon={<DeleteOutlined />} />
                                            </Tooltip>
                                        </Popconfirm>
                                    </HStack>
                                </div>
                                <Text italic type='secondary'>{group.subjectInstruction}</Text>
                                <br /><br />
                                {intersectionBy(assignedTests, group.tests.map(t => ({ _id: t })), '_id').map(test => <Tag>{bilingualText(test.name)}</Tag>)}
                            </Card>
                        ) : <Text type='secondary'>No pricing added</Text>}
                    </Space>
                </>
                :
                null
            }
            {priceGroupModal && <PriceGroupModal defaultData={priceGroupModal?._id ? priceGroupModal : null} currentPackage={currentPackage} assignedTests={assignedTests} visible={priceGroupModal} closeModal={_priceGroupModal} />}
        </div>
    )
}

const PriceGroupModal = ({ visible, closeModal, assignedTests, currentPackage, defaultData }) => {
    const dispatch = useDispatch()
    const [selectedTests, setTests] = useState([])

    const [formData, setData] = useState({})

    useEffect(() => {
        if (defaultData) {
            const tests = intersectionBy(currentPackage.tests.map(t => ({ ...t.test })), defaultData.tests.map(t => ({ _id: t })), '_id')
            setTests(tests.map(d => ({ ...d, key: d._id })))

            const data = { price: defaultData.price, fakePrice: defaultData.fakePrice, showSubjectSelection: defaultData.showSubjectSelection }
            setData(data)
        }
    }, [currentPackage, defaultData])

    const _selectTest = (data) => {
        setTests(data)
    }

    const changeData = (type, value) => {
        setData(d => ({ ...d, [type]: value }))
    }

    const _saveData = () => {
        const data = { ...formData, tests: selectedTests }

        if (defaultData) {
            let offers = [...currentPackage.offlinePrices]
            const indx = findIndex(offers, d => d._id === defaultData._id)
            offers[indx] = data

            dispatch(updatePackageAction({ offlinePrices: offers, packageId: currentPackage._id }))
        } else
            dispatch(updatePackageAction({ offlinePrices: currentPackage.offlinePrices ? [...currentPackage.offlinePrices, data] : [], packageId: currentPackage._id }))
    }

    return (
        <Modal okText='Save' onOk={_saveData} okButtonProps={{ disabled: !formData.price || !selectedTests.length }} width={800} title='Pricing Plan' visible={visible} onCancel={closeModal}>
            <Text>Select Test: </Text>
            <Table
                bordered
                dataSource={assignedTests?.length ? assignedTests.map(d => ({ ...d, key: d._id })) : []}
                pagination={false}
                rowSelection={{ selectedRowKeys: selectedTests?.length ? selectedTests.map(d => d.key) : [], onChange: (_, arr) => _selectTest(arr) }}
            >
                <Table.Column title='Name' dataIndex='name' key='name' render={d => <Text>{d?.en}</Text>} />
                <Table.Column title='Platform' dataIndex='testType' key='testType' />
                <Table.Column title='Time Duration (min)' dataIndex='totalTime' key='time' />
                <Table.Column title='Questions' key='queCont' render={d => size(flatMap(d.sections, 'questions'))} />
                <Table.Column title='Status' dataIndex='status' key='status' />
            </Table>
            <br />
            <Form
                formLayout='horizontal'
            >
                <Space size={20}>
                    <Form.Item required label='Price'>
                        <Input value={formData.price} min={0} onChange={e => changeData('price', e.target.value)} prefix='₹' placeholder='price' type='number' />
                    </Form.Item>
                    <Form.Item label='Fake Price'>
                        <Input value={formData.fakePrice} min={0} onChange={e => changeData('fakePrice', e.target.value)} prefix='₹' placeholder='fake price' type='number' />
                    </Form.Item>
                </Space>
                <Form.Item initialValue={false} label='Show Subject Selection'>
                    <Switch checked={formData.showSubjectSelection} onChange={e => changeData('showSubjectSelection', e)} />
                </Form.Item>
                <Form.Item label='Instruction'>
                    <TextArea value={formData.subjectInstruction} min={0} onChange={e => changeData('subjectInstruction', e.target.value)} placeholder='subjectInstruction' />
                </Form.Item>
            </Form>
        </Modal>
    )
}