import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { map } from "lodash"
import { STATUS } from "../../../Constants"
import { ErrorMessage, SuccessMessage } from "../../../Constants/CommonAlerts"
import { URIS } from "../../../services/api"
import { apis } from "../../../services/api/apis"

export const getAllVisitorsRequest = createAsyncThunk(
    'get/visitors-request/all',
    async (payload, thunkAPI) => {
        const response = await apis.getAllVisitorsRequest(payload)
        const { ok, problem, data } = response
        if (ok) {
            return data
        } else {
            return thunkAPI.rejectWithValue(problem)
        }
    }
)

const initialState = {
    getAllVisitorsRequestStatus: STATUS.NOT_STARTED,
}

const visitorsSlice = createSlice({
    name: "visitors",
    initialState,
    reducers: {
    },
    extraReducers: {
        [getAllVisitorsRequest.pending]: (state, action) => {
            state.getAllVisitorsRequestStatus = STATUS.FETCHING
        },
        [getAllVisitorsRequest.fulfilled]: (state, action) => {
            state.getAllVisitorsRequestStatus = STATUS.SUCCESS
            state.visitorsRequests = action.payload
        },
        [getAllVisitorsRequest.rejected]: (state, action) => {
            state.getAllVisitorsRequestStatus = STATUS.FAILED
        },
    },
})

export const visitorsReducer = visitorsSlice.reducer