import { Button, Card, Checkbox, Col, Input, Row, Table } from "antd";
import _, { concat, filter, find, map, omit } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { search } from ".";
import { ExportExcel } from "../../components/ExportExcel";
import { STATUS } from "../../Constants";
import {
  resetUpdatePkgStudentStatus,
  upadatPkgStudentCenterAction,
} from "../../redux/reducers/packages";
import { sheetToJSON } from "../../utils/FileHelper";

export default function UploadNewStudents(props) {
  const { previousStudents, excelData, packageId, setActiveTab } = props;
  const [editTableData, setEditTableData] = useState([]);
  const [exportEmptyCenter, setExportEmptyCenter] = useState(false);
  const [inputKey, setInputKey] = useState(1);
  const dispatch = useDispatch();

  const { updatePkgStudentCenterStatus } = useSelector((s) => ({
    updatePkgStudentCenterStatus: s.packages.updatePkgStudentCenterStatus,
  }));

  useEffect(() => {
    if (updatePkgStudentCenterStatus === STATUS.SUCCESS) {
      dispatch(resetUpdatePkgStudentStatus());
      setEditTableData([]);
      setExportEmptyCenter(false);
      setActiveTab("1");
    }
  }, [
    dispatch,
    setExportEmptyCenter,
    setEditTableData,
    setActiveTab,
    updatePkgStudentCenterStatus,
  ]);

  const onExcelParserSave = (data) => {
    setInputKey((p) => p + 1);
    const removeEmptyData = filter(data, (d) => Object.values(d).join(""));
    const newResult = map(removeEmptyData, (d) => {
      return {
        packages: [
          {
            package: packageId,
            city: d.city,
            subject: d.subject,
          },
        ],
        rollNumber: {
          finalRoll: d.rollNo,
          center: d.updateCenter,
        },
        user: {
          name: d.name,
          contact: d.contact,
          email: d.email,
          dob: d.dob,
        },
        prevCenter: d.center, //find(excelData, s => s?.rollNo === d.rollNo )?.center || ''
      };
    });
    setEditTableData(newResult);
  };

  const convertFile = (e) => {
    sheetToJSON(e.target.files, onExcelParserSave);
  };

  const handleUpload = () => {
    let data = map(editTableData, (s) => {
      let studentData = {
        roll: s.rollNumber.finalRoll,
        center: s.rollNumber.center,
        meta: {},
      };
      if (s.packages[0].city) studentData.meta.city = s.packages[0].city;
      if (s.packages[0].subject)
        studentData.meta.subject = s.packages[0].subject;
      return studentData;
    });
    dispatch(upadatPkgStudentCenterAction(data));
  };

  const downloadExcelData = useMemo(() => {
    let newExcelDonloadData = exportEmptyCenter
      ? filter(excelData, (s) => !s.center)
      : excelData;
    return newExcelDonloadData;
  }, [excelData, exportEmptyCenter]);

  return (
    <div>
      <Card loading={"1" === STATUS.FETCHING}>
        <div>
          {previousStudents > 0 && (
            <div
              style={{
                color: "primary",
                margin: "20px 0px",
                textAlign: "center",
                fontWeight: "bold",
                backgroundColor: "#f1f1f1",
                padding: "10px",
              }}
            >
              {previousStudents} students have been added to this package
            </div>
          )}

          <Row style={{ margin: "20px 0px", justifyContent: "space-between" }}>
            <Col>
              <Button
                loading={updatePkgStudentCenterStatus === STATUS.FETCHING}
                disabled={!editTableData?.length}
                type="primary"
                onClick={handleUpload}
              >
                Save
              </Button>
            </Col>
            <Col>
              <Row>
                <Col>
                  <Row>
                    <Col span={12}>
                      <div>Download Template</div>
                    </Col>
                    <Col style={{ marginTop: 10, marginBottom: 8 }} span={24}>
                      <ExportExcel
                        data={downloadExcelData}
                        filename="offlineTestResult"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Checkbox
                      isChecked={exportEmptyCenter}
                      onChange={(e) => setExportEmptyCenter(e.target.checked)}
                    >
                      export only with no centers
                    </Checkbox>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col style={{ marginRight: "15px" }}>
                      <div>Upload Excel</div>
                    </Col>
                    <Col key={inputKey}>
                      <Input
                        type="file"
                        accept=".xlsx, .xls, .csv"
                        onChange={convertFile}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          {editTableData?.length > 0 && (
            <div style={{ overflow: "scroll" }}>
              <StudentTable allStudents={editTableData} packageId={packageId} />
            </div>
          )}
        </div>
      </Card>
    </div>
  );
}

export const StudentTable = ({ allStudents, packageId }) => {
  const inputRef = useRef();

  return (
    <Table
      pagination={{ pageSize: 20, showSizeChanger: false }}
      dataSource={allStudents?.length ? _.filter(allStudents, (d) => d) : []}
      bordered
    >
      {" "}
      <Table.Column
        dataIndex=""
        title="Roll No."
        render={(d) => {
          return <div>{d?.rollNumber?.finalRoll || "-"}</div>;
        }}
      />
      <Table.Column
        dataIndex=""
        title="Student Name"
        {...search("name", inputRef)}
        sortDirections={["descend"]}
        defaultSortOrder="descend"
        sorter={(a, b, c) =>
          _.lowerCase(a.user?.name) < _.lowerCase(b.user?.name)
        }
        render={(d) => d.user?.name}
      />
      <Table.Column
        dataIndex=""
        title="Contact"
        render={(d) => d.user?.contact}
        {...search("contact", inputRef)}
      />
      <Table.Column
        dataIndex=""
        title="City"
        render={(d) => {
          const pkg =
            d.packages?.length &&
            _.find(d.packages, (p) => p.package === packageId);
          return pkg?.city;
        }}
      />
      <Table.Column
        dataIndex=""
        title="Subject"
        render={(d) => {
          const pkg =
            d.packages?.length &&
            _.find(d.packages, (p) => p.package === packageId);
          return pkg?.subject;
        }}
      />
      <Table.Column
        dataIndex=""
        title="Previous Center"
        render={(d) => {
          return d.prevCenter;
        }}
      />
      <Table.Column
        dataIndex=""
        title="New Center"
        render={(d) => {
          return d.rollNumber?.center;
        }}
      />
    </Table>
  );
};
