import { RedoOutlined } from "@ant-design/icons";
import { Button, Input, Select, Space, Table } from "antd";
import { filter, find, map } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CommonPageHeader } from "../../components/CommonPageHeader";
import { STATUS } from "../../Constants";
import { getAllVisitorsRequest } from "../../redux/reducers/student-enquiry";
import { getWebsiteDataAction } from "../../redux/reducers/website";

export default function StudentEnquuiryList() {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAllVisitorsRequest())
        dispatch(getWebsiteDataAction());
    }, [dispatch])

    const { websiteData } = useSelector(s => s.website)

    const { visitorsRequests, getAllVisitorsRequestStatus } = useSelector(s => s.visitors)
    const columns = [
        { title: <b>Name</b>, dataIndex: 'name', key: 1 },
        { title: <b>Contact</b>, dataIndex: 'contact', key: 2 },
        { title: <b>Email</b>, dataIndex: 'email', key: 3 },
        { title: <b>Interested Course</b>, dataIndex: 'interestedCourse', key: 4, render: (d) => find(websiteData?.courseCategories, course => course._id === d)?.name?.en },
        { title: <b>Address</b>, dataIndex: 'address', key: 5 },
        { title: <b>Visit Date</b>, width: "130px", dataIndex: 'visitDate', render: (d) => d ? moment(d).format("DD-MM-YYYY") : '', key: 6 },
    ]

    useEffect(() => {
        setFilteredData(visitorsRequests)
    }, [visitorsRequests])

    const [inputFilter, setInputFilter] = useState()
    const [filteredData, setFilteredData] = useState(visitorsRequests)

    const inputHandler = (val) => {
        setInputFilter(pre => ({ ...pre, ...val }))
    }

    const filterData = () => {

        const filteredData = filter(visitorsRequests, f => {
            if (inputFilter) return (inputFilter.name ? f.name.toLowerCase().includes(inputFilter.name.toLowerCase()) : true) && (inputFilter.contact ? f.contact.includes(inputFilter.contact) : true) && (inputFilter.interestedCourse ? f.interestedCourse.includes(inputFilter.interestedCourse) : true)
        })
        setFilteredData(filteredData)
    }

    const resetFilter = () => {
        setFilteredData(visitorsRequests)
        setInputFilter()
    }

    return (
        <div>
            <CommonPageHeader title='Student Enquiry' />
            <br />
            <Space style={{ marginBottom: 10 }}>
                <Input type="text" id="name" placeholder="Search By Name" value={inputFilter?.name} onChange={(e) => inputHandler({ name: e.target.value })} />
                <Input type="number" id="contact" placeholder="Search By Contact" value={inputFilter?.contact} onChange={(e) => inputHandler({ contact: e.target.value })} />
                <Select style={{ width: 150, }} allowClear value={inputFilter?.interestedCourse} placeholder="Select Course" onChange={(e) => inputHandler({ interestedCourse: e })}
                    options={
                        map(websiteData?.courseCategories, course =>
                        ({
                            value: course._id, label: course.name.en
                        })
                        )
                    }
                />
                <Button type="primary" onClick={filterData}>Filter</Button>
                <Button onClick={resetFilter}><RedoOutlined />Reset</Button>
            </Space>
            <br />
            <Table loading={getAllVisitorsRequestStatus === STATUS.FETCHING} bordered columns={columns} dataSource={filteredData}
                pagination={false}
            >
            </Table>
        </div>

    )
}

