import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { Modal, Form, Input, Space, Button, Tooltip } from 'antd'
import { filter } from 'lodash'
import React, { useEffect, useState } from 'react'

export const ExamCentersModal = ({visible, closeModal, onSubmit, defaultCenters}) => {

    let defaultCenter = {name:'', address:'', code:''}
    let [allCenters, setCenters] = useState([defaultCenter])

    useEffect(() => {
        if(defaultCenters?.length)
            setCenters(defaultCenters)
    }, [defaultCenters])
    
    const handleAddCenter = () => {
        setCenters(a => ([...a, defaultCenter]))
    }

    const handleRemoveCenter = (indx) => {
        let centers = [...allCenters]
        centers.splice(indx, 1)
        setCenters(centers)
    }

    const handleChange = (e, type, indx) => {
        let data = [...allCenters.map(d => Object.assign({}, d))]
        data[indx][type] = e.target.value
        setCenters(data)
    }

    const handleSubmit = () => {
        let data = filter(allCenters,d => d.name && d.address)
        onSubmit(data)
        closeModal()
    }

    return(
        <Modal title='Exam Centers' visible={visible} okText='Done' width={'80%'} onCancel={closeModal} 
            onOk={handleSubmit}
        >
            {!allCenters.length ? <Button type='' icon={<PlusOutlined />} onClick={handleAddCenter}>Add More</Button> : null}
              <Form layout='vertical'>
              {allCenters.map((center, i) => {
                const first = i === 0
                return(
                    <div style={{display:'flex', marginTop:10, alignItems:'start', width:'100%'}} key={i}>
                        <Form.Item required style={{flex:1, marginRight:10}} label='Center Name'>
                            <Input onChange={(e) => handleChange(e, 'name', i)} value={center.name} placeholder='Center Name' />
                        </Form.Item>
                        <Form.Item required style={{flex:2, marginRight:10}} label='Address'>
                            <Input.TextArea onChange={(e) => handleChange(e, 'address', i)} value={center.address} key='address' placeholder='Address'/>
                        </Form.Item>
                        <Form.Item style={{flex:2}} label='Code'>
                            <Space>
                            <Input type='number' onChange={(e) => handleChange(e, 'code', i)} value={center.code} key='code' placeholder='Code'/>
                            &nbsp;&nbsp;&nbsp;
                            {first ?
                                <Space>
                                    <Tooltip title='Remove'>
                                        <Button icon={<CloseOutlined />} onClick={() => handleRemoveCenter(i)}/>
                                    </Tooltip>
                                    <Button type='' icon={<PlusOutlined />} onClick={handleAddCenter}>Add More</Button>
                                </Space>
                                :
                                <Tooltip title='Remove'>
                                    <Button icon={<CloseOutlined />} onClick={() => handleRemoveCenter(i)} />
                                </Tooltip>
                            }
                            </Space>
                        </Form.Item>
                        </div>
                ) }
              )}
              </Form>
        </Modal>
    )
}