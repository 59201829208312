import { DeleteOutlined, EditFilled, EditOutlined, InsertRowBelowOutlined, MessageOutlined, PlusOutlined, RedoOutlined, UserOutlined } from '@ant-design/icons'
import { Text } from '@chakra-ui/react'
import { Button, Card, Col, Form, Input, List, Popover, Row, Select, Skeleton, Table, Tag } from 'antd'
import { find, intersectionBy, orderBy } from 'lodash'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useAuthUser } from '../../App/Context'
import { CommonPageHeader } from '../../components/CommonPageHeader'
import { TotalCountBox } from '../../components/TotalCountBox'
import { STATUS } from '../../Constants'
import { ConfirmAlert } from '../../Constants/CommonAlerts'
import { ROUTES } from '../../Constants/Routes'
import { getCoursesAction } from '../../redux/reducers/courses'
import { deletePackageAction, getPackagesAction } from '../../redux/reducers/packages'
import { getAllProductsAction } from '../../redux/reducers/products'
import { getAllTestsAction } from '../../redux/reducers/test'
import { bilingualText, useQueryParams } from '../../utils/FileHelper'
import { NotificationModal } from '../Notifications/NotificationModal'

export const OfflineTestPackages = () => {

    const dispatch = useDispatch()
    const auth = useAuthUser()
    const queries = useQueryParams()
    const params = useParams()
    const history = useHistory()
    let location = useLocation()

    const publishStatuses = ['No', 'Yes', 'Coming Soon']
    const { packages, configData, products, course, testsList } = useSelector((state) => ({
        packages: state.packages,
        configData: state.lmsConfig,
        products: state.product,
        course: state.course,
        testsList: state.test?.testsList,
    }))

    useEffect(() => {
        dispatch(getPackagesAction({ isOfflineTestSeriesPackage: true }))
        dispatch(getAllTestsAction())
    }, [dispatch])

    let dataSource = useMemo(() => {
        return packages.packagesList?.length ?
            orderBy(packages.packagesList, ['createdAt'], ['desc']).map(pkg => ({
                priority: pkg.priority,
                package: pkg.name,
                exams: pkg.exams,
                published: pkg.published,
                price: { price: pkg.price, fakePrice: pkg.fakePrice },
                actions: pkg,
                serial: pkg.serial,
                createdAt: moment(pkg.createdAt).format('DD-MM-YYYY'),
                cover: pkg.carousel.length ? pkg.carousel[0] : null,
                studentCount: pkg.studentCount,
            }))
            : []
    }, [packages.packagesList])

    const [defaultSyllabus, setDefaultSyllabus] = useState({ exams: [] })
    const [showAction, changeShowAction] = useState()
    const [notifyModal, openNotifyModal] = useState()
    const [finalFilters, setFinalFilters] = useState(() => {
        if (queries.get('name') || queries.get('exams') || queries.get('type') || queries.get('published')) {
            return { name: queries.get('name'), exams: queries.get('exams'), type: queries.get('type'), published: queries.get('published') }
        }

        return {}
    })

    const [filters, changeFilters] = useState(() => {
        if (queries.get('name') || queries.get('exams') || queries.get('type') || queries.get('published')) {
            return { name: queries.get('name'), exams: queries.get('exams'), type: queries.get('type'), published: queries.get('published') }
        }

        return {}
    })

    useEffect(() => {
        if (configData.defaultDataStatus === STATUS.SUCCESS) {
            let defaultData = configData.defaultData
            if (defaultData)
                setDefaultSyllabus({ exams: defaultData.exams })
        }
    }, [configData.defaultData, configData.defaultDataStatus])

    const updatePackage = (id) => {
        history.push('/add-offline-test-package/1/' + id)
    }

    const previewPackage = (id) => {
        history.push(`/preview-package/${id}/true`)
    }

    const toggleAction = (id) => {
        changeShowAction(id === showAction ? null : id)
    }

    const deletePackage = (id) => {
        changeShowAction(false)
        ConfirmAlert(() => dispatch(deletePackageAction({ id })), 'Sure?')
    }


    const sendMessage = (id) => {
        let pkg = packages.packagesList?.length ? find(packages.packagesList, p => p._id === id) : null

        openNotifyModal(pkg)
        changeShowAction(false)
    }

    const pkgStudents = (id) => {
        history.push('/package-students/' + id)
    }

    const searchPackage = (e) => {
        changeFilters(a => ({ ...a, name: e.target.value }))
    }

    const selectPublish = (e) => {
        changeFilters(a => ({ ...a, published: e }))
    }

    const selectExam = e => {
        changeFilters(a => ({ ...a, exams: e }))
    }

    const actionsList = [
        { title: 'Edit Package', id: 3, callback: updatePackage, icon: <EditOutlined /> },
    ]

    const columns = [
        { title: <b>Code</b>, width: 80, fixed: 'left', dataIndex: 'serial', key: 8 },
        {
            title: <b>Package</b>, fixed: 'left', dataIndex: 'package', key: 2,
            render: d => (<Text>{bilingualText(d)}</Text>)
        },
        { title: <b>Priority</b>, width: 100, dataIndex: 'priority', key: 1 },
        {
            title: <b>Exams</b>, dataIndex: 'exams', key: 3,
            render: ids => {
                const exams = intersectionBy(defaultSyllabus.exams, ids.map(d => ({ _id: d })), '_id')
                return (
                    <div>
                        {exams.length ?
                            exams.map(exam =>
                                <Tag>{exam.name.en}</Tag>
                            )
                            : null
                        }
                    </div>
                )
            }
        },
        {
            title: <b>Published</b>, width: 120, dataIndex: 'published', key: 5,
            render: d => (<Tag color={d === 1 ? 'green' : d === 0 ? 'red' : d === 2 ? 'orange' : 'red'}>{publishStatuses[d || 0]}</Tag>)
        },
        {
            title: <b>Price</b>, dataIndex: 'price', key: 6, width: 100,
            render: d => (
                <div>
                    <span style={{ color: 'green' }}>₹ {d.price}</span><br />
                    {d.fakePrice ? <span style={{ color: 'red', textDecoration: 'line-through' }}>₹ {d.fakePrice}</span> : null}
                </div>
            )
        },
        { title: <b>Created At</b>, dataIndex: 'createdAt', key: 'createdAt', width: 110 },
        { title: <b>Students</b>, dataIndex: 'studentCount', render: d => d || 0, key: 'studentCount', width: 110 },
        {
            title: <b>Actions</b>, width: 110, fixed: 'right', dataIndex: 'actions', key: 7,
            render: d => {
                return (
                    <Popover trigger='click' placement='bottom' visible={showAction == d._id} onVisibleChange={() => toggleAction(d._id)}
                        content={
                            <List dataSource={actionsList}
                                renderItem={item =>
                                    <List.Item onClick={() => item.callback(d._id)} className='hover-list-item' style={{ cursor: 'pointer' }}>
                                        <span style={{ marginRight: '10px' }}>{item.icon}</span>{item.title}
                                    </List.Item>
                                }
                            />
                        }
                    >
                        <Button shape='round' >Actions</Button>
                    </Popover>
                )
            }
        },
    ]

    useEffect(() => {
        let data = {}
        if (finalFilters)
            data = { ...finalFilters, exams: finalFilters.exams ? [finalFilters.exams] : [], isOfflineTestSeriesPackage: true }

        dispatch(getPackagesAction(data))
    }, [finalFilters, dispatch])

    const filterPackages = () => {
        let { exams, type, published, name } = filters
        let string = ''

        function setQuery(key, value) {
            if (string)
                string = string + `&${key}=${value}`
            else
                string = `?${key}=${value}`
        }

        if (exams?.length)
            setQuery('exams', exams)

        if (type)
            setQuery('type', type)

        if (published || published === 0)
            setQuery('published', published)

        if (name)
            setQuery('name', name)

        history.push({
            pathname: '/offline-test-packages/1',
            search: string
        })

        let data = { exams: exams, type: type, published, name: name }
        setFinalFilters(data)
    }

    const clearData = () => {
        changeFilters({})
        setFinalFilters({})
        history.push({
            pathname: '/offline-test-packages/1',
            search: null
        })
    }

    const changePage = (e) => {
        history.push({ pathname: '/offline-test-packages/' + e.current, search: location.search })
    }

    const _addPackage = () => {
        history.push('/add-offline-test-package/1')
    }
    return (
        <div>
            <CommonPageHeader title='Manage Offline Test Packages'
                extra={
                    <Button icon={<PlusOutlined/>} onClick={_addPackage}>Add Package</Button>
                }
            />
            <br />
            <Card loading={false}>
                <div>
                    <Form layout='vertical'>
                        <Row>
                            <Col span={3}>
                                <Form.Item label='Search Name'>
                                    <Input placeholder='Enter Package Name' value={filters.name} onChange={searchPackage} />
                                </Form.Item>
                            </Col>
                            <Col span={3} offset={1}>
                                <Form.Item label='Published Status'>
                                    <Select onChange={selectPublish} allowClear value={filters.published} placeholder='Select Published Status'>
                                        <Select.Option value={0}>No</Select.Option>
                                        <Select.Option value={1}>Yes</Select.Option>
                                        <Select.Option value={2}>Coming Soon</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={3} offset={1}>
                                <Form.Item label='Exams'>
                                    <Select onChange={selectExam} allowClear value={filters.exams} placeholder='Select Exam'>
                                        {configData.defaultData?.exams.length ?
                                            configData.defaultData.exams.map(exam =>
                                                <Select.Option key={exam._id}>{exam.name.en}</Select.Option>
                                            )
                                            : null
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={5} offset={1} style={{ paddingTop: '28px' }}>
                                <Button onClick={filterPackages}>Apply</Button>
                                <Button style={{ marginLeft: '10px' }} icon={<RedoOutlined />} onClick={clearData}>Reset</Button>
                            </Col>
                        </Row>
                    </Form>
                    <br />
                    <TotalCountBox count={dataSource?.length} />
                    <br />
                    <Table bordered scroll={{ x: 1800 }}
                        dataSource={dataSource} columns={columns} key={dataSource?.length}
                        pagination={{ position: ['bottomCenter'], current: parseInt(params?.pageNumber) || 1 }} onChange={changePage}
                    />
                </div>
            </Card>
            {notifyModal ? <NotificationModal visible={notifyModal} closeModal={() => sendMessage()} notifiableType='Package' notifiableIds={[notifyModal]} /> : null}
        </div>
    )
}