import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import {
  Avatar,
  Card,
  Col,
  Descriptions,
  Empty,
  Row,
  Form,
  Button,
  Space,
  Tag,
} from "antd";
import Text from "antd/lib/typography/Text";
import React, { useEffect, useState } from "react";
import {
  getStudentAction} from "../../redux/reducers/student";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { STATUS } from "../../Constants";
import _ from "lodash";
import { UpdateProfile } from "../../components/UpdateStudentProfile";
import { UpdateAddress } from "../../components/UpdateStudentAddress";
import { AssignCourses } from "./AssignStudentCourses";
import { ProfilePicModal } from "./ProfilePicModal";
import { AssignPackage } from "./AssignStudentPackage";
import { CommonPageHeader } from "../../components/CommonPageHeader";

export const StudentProfile = () => {
  const dispatch = useDispatch();
  const [] = Form.useForm()
  const params = useParams();
  const { student, course, assignCourseStatus, assignPkgStatus } = useSelector((state) => ({
    student: state.student,
    instituteId: state.user.user.staff.institute?._id,
    institute: state.instituteStaff,
    batches:state.batches,
    assignCourseStatus:state.student.studCoursesAssignStatus,
    assignPkgStatus:state.student.assignPkgStatus
  }));

  const [updateProfileDrawer, setUpdateProfileDrawer] = useState(false)
  const [updateAddressDrawer, setUpdateAddressDrawer] = useState(false)
  const [imageModal, openImageModal] = useState()

  useEffect(() => {
    dispatch(getStudentAction({ userId: params.id }));
  }, [params.id, dispatch]);


  useEffect(() => {
    if(assignCourseStatus === STATUS.SUCCESS || assignPkgStatus === STATUS.SUCCESS)
      dispatch(getStudentAction({ userId: params.id }));
  }, [ assignCourseStatus, dispatch, assignPkgStatus ])

  useEffect(() => {
    if(student.updateStudentStatus == STATUS.SUCCESS)
      setUpdateProfileDrawer(false)
  }, [student.updateStudentStatus])

  const openImage = (img) => {
    openImageModal(img)
  }

  const currentStudent = student.currentStudent && student.currentStudent.user;
  return (
    <div style={{ margin: "0 130px" }}>
      {student.getStudentStatus === STATUS.SUCCESS && currentStudent ? (
        <>
          <div style={{ position: "relative" }}>
            <CommonPageHeader title={'Profile'}/>
            <br/>
            <div style={{ height: "110px" }} className="profileContainer"></div>
            <Card style={{ paddingTop: "90px" }}>
              {currentStudent.avatar ?
                <Avatar
                  className="profileImg"
                  onClick={() => openImage(currentStudent)}
                  style={{ position: "absolute", top: -90, cursor:'pointer', background: "white" }}
                  size={180}
                  src={currentStudent.avatar}
                />
                :
                <div  style={{ position: "absolute", width:'180px', height:'180px', borderRadius:'50%', display:'flex', justifyContent:'center', border:'2px solid #348A7A', 
                  alignItems:'center', top: -90, cursor:'pointer', background: "white" }}
                  onClick={() => openImage(currentStudent)}
                >
                  Upload Pic
                </div>
              }
              <Row>
                <Col span={20}>
                  <Text style={{ fontSize: "30px" }}>{currentStudent.name}</Text>
                  <br />
                </Col>
                <Col span={4} style={{ textAlign: "right" }}>
                  {currentStudent.emitra && <Tag style={{fontSize:18, padding:4}} color="orange">eMitra</Tag>}
                </Col>
              </Row>
            </Card>
          </div>
          <br />
          <PersonalInfo currentStudent={currentStudent} updateProfile={() => setUpdateProfileDrawer(true)} />
          <br/>
          <Address currentStudent={student.currentStudent} updateAddress={() => setUpdateAddressDrawer(true)}/>
          <br/>
          <AssignPackage course={course} student={student.currentStudent}/>
          {/* <br/>
          <AssignCourses course={course} student={student.currentStudent}/> */}
        </>
      ) : student.getStudentStatus === STATUS.FETCHING ? (
        <Card loading={student.getStudentStatus === STATUS.FETCHING}></Card>
      ) : (
        <Card>
          <Empty description='something went wrong' />
        </Card>
      )}

      {updateProfileDrawer ? <UpdateProfile currentStudent={student.currentStudent} visible={updateProfileDrawer} closeDrawer={() => setUpdateProfileDrawer(false)}/>: null }
      {updateAddressDrawer ? <UpdateAddress currentStudent={student.currentStudent} visible={updateAddressDrawer} closeDrawer={() => setUpdateAddressDrawer(false)}/>: null }
      {imageModal ? <ProfilePicModal closeModal={() => openImageModal(null)} visible={imageModal} student={currentStudent} /> : null}
      <br />
    </div>
  );
};

const PersonalInfo = ({ currentStudent, updateProfile }) => {
  return (
    <Card>
      <Descriptions extra={[<Button type='link' onClick={() => updateProfile()} icon={<EditOutlined style={{fontSize:"30px"}} />}></Button>]} 
        title={<Text style={{fontSize:'18px'}}>Personal Information</Text>} bordered
      >
       
        <Descriptions.Item label="Gender">
          {currentStudent.gender}
        </Descriptions.Item>
        <Descriptions.Item label="Date of Birth">
          {currentStudent.dob && moment(currentStudent.dob).format('DD-MM-YYYY') || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="E-mail">
          {" "}
          {currentStudent.email || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Contact Number">
          {currentStudent.contact || '-'}
        </Descriptions.Item>
        <Descriptions.Item label="Username">
          {currentStudent.username || '-'}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

const Address = ({ currentStudent, updateAddress }) => {
  return (
    <Card>
      <Descriptions title={<Text style={{fontSize:'18px'}}>Address</Text>} bordered style={{fontSize:'18px'}}
        extra={[
            <Space size='large'>
              <Button onClick={updateAddress} icon={<EditOutlined style={{fontSize:"30px"}} />} type='link'></Button>
              {/* <Button onClick={updateAddress} icon={<PlusOutlined style={{fontSize:"30px"}} />} type='link'></Button> */}
            </Space>
          ]}
      > 
          <>
            <Descriptions.Item label="Address" span={4}>
              {currentStudent.address?.address || '-'}
            </Descriptions.Item>
            <Descriptions.Item label="State">
              {currentStudent.address?.state || '-'}
            </Descriptions.Item>
            <Descriptions.Item label="City">
              {currentStudent.address?.city || '-'}
            </Descriptions.Item>
            <Descriptions.Item label="Pincode">
              {currentStudent.address?.pincode || '-'}
            </Descriptions.Item>
          </>
      </Descriptions>
    </Card>
  );
};
