import React, { useState } from 'react'
import { useEffect } from 'react';
import { PRINT_TYPE } from '../Constants';
import { TestPaperPrint } from '../routes/ManageTests/TestPaperPrint';
import { ResultDataTable } from '../routes/ManageTests/ResultReportTable';
import {AnswerKeyTable} from '../routes/AnswerKeys/AnswerKeyTable';
import { bilingualText } from '../utils/FileHelper';
import { LoadingModal } from './LoadingModal';
import { TopicReview } from '../routes/SurveyReport';
import DiscritptiveAnswer from '../routes/SurveyReport/DiscritptiveAnswer';

export const GenPrint = (props) => {
    let [loading, setLoading] = useState(false)
    useEffect(() => {
        window.focus();
        setLoading(true)
        document.title = props.data.test?.name ? bilingualText(props.data.test.name) : 'Question Paper'
        setTimeout(() => {setLoading(false); window.print()}, props.data.timeout || 200);
    }, [])

    return(
        <div>
            {props.type == PRINT_TYPE.TEST_PAPER ? 
                <TestPaperPrint {...props.data}/>
            : props.type == PRINT_TYPE.STUDENTS_RESULT_TABLE ? 
                <ResultDataTable data={props.data}/>
            : props.type == PRINT_TYPE.TEST_ANSWER_KEY ? 
                <AnswerKeyTable {...props.data}/>
            : props.type == PRINT_TYPE.PRINT_SURVEY_REPORT ?
              <TopicReview { ...props.data }/>
            : props.type === PRINT_TYPE.PRINT_SURVEY_DISCRIPTIVE_ANSWER ?
              <DiscritptiveAnswer {...props.data}/>
            : null}
            {loading ? <LoadingModal text={'Printing...'} visible={loading}/> : null}
        </div>
    )
}