const localBaseUrl = "http://localhost:4001/";
const testBaseUrl = "https://api.test.prayas.tutorlog.in/";
const liveBaseUrl = "https://api.prayassikar.com/";

export function getBaseUrl(host) {
  if (host === "localhost") {
    return {
      base: localBaseUrl,
      students: "https://student.test.prayas.tutorlog.in/",
    };
  } else if (host === "admin.test.prayas.tutorlog.in") {
    return {
      base: testBaseUrl,
      students: "https://student.test.prayas.tutorlog.in/",
    };
  } else if (host === "admin.prayassikar.com") {
    return {
      base: liveBaseUrl,
      students: "https://student.prayassikar.com/",
    };
  }
}

const isclient = typeof window !== "undefined";
if (isclient) {
  console.log("[loc]", window.location);
}

const bburls = getBaseUrl(window.location?.hostname);
export const BaseURL = (isclient && bburls?.base) || testBaseUrl;

//used for test
export const BaseURL_WEB =
  (isclient && bburls?.students) || "https://public.coco.coachinglog.in/";
