import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Empty, Form, Input, Select } from 'antd';
import { Modal } from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import { STATUS } from '../../Constants';
import { filter, find, map } from 'lodash';
import { copyCourseSubjectAction, resetCopyCourseSubjectStatus } from '../../redux/reducers/courses';


const { Option } = Select;


export default function CopyCourseSubjectModal({ visible, copyToCourseId, closeModal }) {
    
    const [form] = Form.useForm();
    const [ selectedCourse, setSelectedCourse ] = useState(null);
    const [ selectedSubject, setSelectedSubject ] = useState(null);
    const dispatch = useDispatch();
    const { course } = useSelector((state) => ({
        course: state.course,
    }));
    const onFinish = useCallback(() => {
        dispatch(copyCourseSubjectAction({
            oldCourseId: selectedCourse,
            newCourseId: copyToCourseId,
            subjectId: selectedSubject
        }));
    }, [dispatch, selectedCourse, selectedSubject, copyToCourseId])

    const onCourseChange = useCallback((id) => {
        setSelectedCourse(id)
        setSelectedSubject();
        form.setFieldsValue({ subjects: null });
    }, [setSelectedCourse, setSelectedSubject, form])

    const filterCourses = useMemo( () => { 
        return  filter(course?.courseList, c => c._id !== copyToCourseId );
     },[copyToCourseId, course])

    const filterSubjects = useMemo( () => { 
        return find(course?.courseList, s => s._id === selectedCourse )?.subjects;
     },[course, selectedCourse])


     const handleCloseModal = useCallback( () => {
        form.resetFields();
        closeModal();
    },[form, closeModal])

     useEffect(() => {
        if(course.copyCourseSubjectStatus === STATUS.SUCCESS){
            dispatch(resetCopyCourseSubjectStatus())
            handleCloseModal();
        }
     }, [course.copyCourseSubjectStatus, handleCloseModal, dispatch])
    
    return (
        <div>
            <div>
                <Modal title='Copy Course Subjects From' visible={visible}
                    okText='Copy Subjects'
                    onOk={onFinish} onCancel={handleCloseModal}
                    okButtonProps={{ disabled: !selectedCourse || !selectedSubject }}
                    confirmLoading={ course.copyCourseSubjectStatus === STATUS.FETCHING }
                >
                    {course.getCoursesStatus === STATUS.SUCCESS &&
                        filterCourses?.length ? (
                        <Form name="control-ref" form={form}>
                            <Form.Item name="course" label="Course" rules={[{ required: true }]}>
                                <Select
                                    placeholder="Select a option and change input text above"
                                    onChange={onCourseChange}
                                    allowClear
                                >
                                    {
                                        map(filterCourses, course => 
                                            <Option key={course._id} value={course._id}>{course.name}</Option> )
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item name="subjects" label="Subjects" rules={[{ required: true }]}>
                                <Select
                                    // mode="multiple"
                                    placeholder="Select a option and change input text above"
                                    onChange={setSelectedSubject}
                                    allowClear
                                >
                                    {
                                        map(filterSubjects, s =>
                                            <Option key={s._id} value={s._id}>{s.displayName}</Option> )
                                    }
                                </Select>
                            </Form.Item>
                        </Form>
                    ) : (
                        <Empty description="No course added" />
                    )}
                </Modal>
            </div>
        </div>
    )
}
